
import React, {useState} from 'react'
import * as styles from './Modal.module.scss'
import {Button, Modal} from 'react-bootstrap'

const CustomModal = ({showit, handleShow}) => {

    return (
      <>
  
        <Modal
          show={showit}
          onHide={handleShow}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            I will not close if you click outside me. Don't even try to press
            escape key.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => handleShow(false)}>
              Close
            </Button>
            <Button variant="primary">Understood</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default CustomModal;