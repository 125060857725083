import React, { Suspense, lazy } from 'react'
import * as app_styles from './App.module.scss'
import NavBar from './components/NavBar/NavBar'
import MainSection from './components/MainSection'
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
  useParams
} from 'react-router-dom'

import countries_avaliable from './utils/countries_avaliable.json'

const CategoryList = lazy(() => {
  return import('./components/CategoryList')
})
const StoreList = lazy(() => {
  return import('./components/StoreList/StoreList')
})
const Blog = lazy(() => {
  return import('./components/Blog')
})
const AppsSection = lazy(() => {
  return import('./components/AppsSection')
})
const Footer = lazy(() => {
  return import('./parts/Footer')
})


const Heads = () => {
  return (
    <>
    <NavBar />
      <MainSection />
      </>
  )
}
const Footers = () => {
  return (
      <Suspense
        fallback={
          <>
            <div></div>
          </>
        }
      >
        <Footer />
      </Suspense>
  )
}

const SearchPage = () => {
  return (
    <Suspense
        fallback={
          <>
            <div></div>
          </>
        }
      >
        <StoreList />
      </Suspense>
  )
}
const Home = () => {
  return (
    <>
 
      <Suspense
        fallback={
          <>
            <div></div>
          </>
        }
      >
        <CategoryList />
      </Suspense>
      <Suspense
        fallback={
          <>
            <div></div>
          </>
        }
      >
        <AppsSection />
      </Suspense>
      <Suspense
        fallback={
          <>
            <div></div>
          </>
        }
      >
        <StoreList />
      </Suspense>
      <Suspense
        fallback={
          <>
            <div></div>
          </>
        }
      >
        <Blog />
      </Suspense>
        
    
</>
  )
}


function App () {
  let default_country = 'GP'
  let default_language = 'fr'

  let { country } = useParams()

  const isInList = _country => {
    return countries_avaliable.codes.includes(String(_country).toUpperCase())
  }
  const getCountry = _country => {
    let c = _country
    if (!isInList(_country)) {
      c = default_country
    }

    return c.toLowerCase()
  }

  return (
    <Router>
         <Heads/>

      <Switch>
      <Route exact path='/search'>
        <div className={app_styles.main_container}>
            <SearchPage />
        </div>
          {/* <Redirect exact to="/fr/partners" /> */}
        </Route>
        <Route exact path='/'>
        <div className={app_styles.main_container}>
          <Home />
          </div>
          {/* <Redirect exact to="/fr/partners" /> */}
        </Route>
        {/* <Route exact path='/:country' render={props => (
            <Redirect to={`/${getCountry(props.match.params.country)}/partners?hreflang=`+default_language}/>
        )}> */}

        {/* </Route> */}

        <Route
          path='/:country/partners'
          render={props => {
            if (isInList(props.match.params.country)) {
              return <Home />
            } else {
              return (
                <Redirect
                  to={
                    `/${getCountry(
                      props.match.params.country
                    )}/partners?hreflang=` + default_language
                  }
                />
              )
            }
          }}
        ></Route>

        <Route path='*'>
          <Home />
        </Route>
      </Switch>
      <Footers/>
    </Router>
  )
}

export default App
