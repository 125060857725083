import React from 'react'
import * as styles from './MainSection.module.scss'

import location from './../assets/img/MainSection/location.svg'
import arrow from './../assets/img/arrow_back.svg'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import {Animated} from "react-animated-css";

const SearchInput = () => {
  return (
    <>
      <input
        type='text'
        className={[styles.controlled_bar, styles.input_searcher].join(' ')}
        placeholder={'Rechercher un commerce'}
      />
    </>
  )
}
const SearchButton = () => {
  return (
    <>
     <Link  to={`/search`} >
                  
      <button
        className={[styles.controlled_bar, styles.button_searcher].join(' ')}
      >
        Chercher
      </button>
      </Link>
    </>
  )
}
const GeoSelector = () => {
  return (
    <>
      <div
        className={
          `nav-item dropdown  ` +
          styles.round_language +
          ' ' +
          styles.controlled_bar
        }
      >
        <a
          className={'nav-link dropdown-toggle ' + styles.custom_arrow}
          href='#'
          id={styles.navbarDropdown}
          role='button'
          data-toggle='dropdown'
          aria-haspopup='true'
          aria-expanded='false'
        >
          <div className={styles.location_w}>
            <img src={location} width='19px' height='26px' />
            <div className={styles.location_name}>Paris</div>
            <img
              src={arrow}
              style={{ transform: 'rotate(270deg)' }}
              width='14px'
              height='26px'
            />
          </div>
        </a>
        <div
          className={'dropdown-menu ' + styles.dropdownmenu_custom}
          aria-labelledby='navbarDropdown'
        >
          <a className='dropdown-item' href='#'>
            Paris
          </a>
          <a className='dropdown-item' href='#'>
            France
          </a>
          {/* <div className="dropdown-divider"></div> */}
          <a className='dropdown-item' href='#'>
            Dominican Republic
          </a>
        </div>
      </div>
    </>
  )
}
const Searcher = () => {
  return (
    <>
      <div className={styles.searcher_wrapper}>
        <div>
          <GeoSelector />
        </div>
        <div>
          <SearchInput />
        </div>
        <div>
          <SearchButton />
        </div>
      </div>
    </>
  )
}

const MainSection = () => {
  const { t } = useTranslation()
  const location = useLocation();
const [showText, setShowText] = React.useState(false);


React.useEffect(() => {
        if(location.pathname != '/search') {
            setShowText(true)
        } else {
            setShowText(false)
        }
        console.log('location:', location.pathname)
  },  [location])

  return (
    <>
      <div className={styles.main_wrapper}>
        <div className={styles.title_hub}>Here goes a title for city hub</div>
        <Searcher />
        <Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={0} isVisible={showText}>
            <div className={styles.subtitle}>
            {t('Un autre façon de découvrir les bons plans')}
            </div>
        </Animated>
      </div>
    </>
  )
}

export default MainSection
