import React ,{Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import './index.scss';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
// import $ from 'jquery';
// import Popper from 'popper.js';
// import 'bootstrap/dist/js/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js'
import 'react-lazy-load-image-component/src/effects/blur.css';
// import i18n as from "i18next";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-oldschool-dark'
import * as serviceWorker from './serviceWorker';
import ReactSEO from 'react-seo';

// const App = lazy(()=>{return import('./App')})
// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

ReactSEO.startMagic([{url:''}],renderDOM);
      function renderDOM(){
ReactDOM.render(
  <BrowserRouter>
  {/* // <Suspense fallback={<>Loading...</>} > */}
  <AlertProvider template={AlertTemplate} {...options}>
    
  <I18nextProvider i18n={i18n}>
    
    <App />
    </I18nextProvider>
    </AlertProvider>
    {/* //  </Suspense>, */}
  </BrowserRouter>,

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  document.getElementById('root')
);
      }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorker.register();
