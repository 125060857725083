import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as es  from './i18n/es.json';
import * as en  from './i18n/en.json';
import * as fr  from './i18n/fr.json';
i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translations: en.default
    },
    es: {
      translations: es.default
    },

    fr: {
      translations: fr.default
    }
    
  },
  fallbackLng: "en",
  debug: false,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  
  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;